.language-picker {
    span {
        padding: 0px 8px;
        color: rgb(163, 163, 163);
        cursor: pointer;
        font-size: 14px;
        &.selected {
            color: white;
            font-weight: bold;
        }
        &.separetor {
            font-weight: 500;
        }
    }
}