@font-face {
  font-family: Inter;
  src: url(../public/fonts/Inter/static/Inter-Regular.ttf);
}

@import '../node_modules/bootstrap/scss/bootstrap.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  overflow: hidden;
}

h1, h2, h3, h4, p, a, span, li, button {
  font-family: Inter;
  letter-spacing: 0.2em;
  color: white;
  margin: 0;
  padding: 0;
}

ul {
  padding-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button {
  border: none;
  &:disabled {
    background-color: rgb(156, 156, 156) !important;
  }
}

@import './styles/variables.scss';
@import './styles/about-us.scss';
@import './styles/contact.scss';
@import './styles/home.scss';
@import './styles/project.scss';
@import './styles/projects.scss';

@import './styles/header.scss';
@import './styles/menu.scss';
@import './styles/transition.scss';
@import './styles/floating-icons.scss';
@import './styles/language-picker.scss';

.base-background {
  position: relative;
  background: radial-gradient(50% 50% at 50% 50%, rgba(80, 109, 133, 0) 0%, rgba(80, 109, 133, 0.2) 100%), linear-gradient(
        180deg
        , rgba(80, 109, 133, 0) 0%, rgba(80, 109, 133, 0.2) 100%), #19181D;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: -1;
  }
}

.container {
  width: 90%;
  max-width: calc(1920px - 10%);
  margin: 0 auto;
  padding: 0;
  position: relative;
}

.page-container {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow-x: hidden;
  &.current-page {
    z-index: 50;
    opacity: 0;
    animation: togglePage 0.6s forwards;
  }
  &.last-page {
    z-index: 10;
    opacity: 1;
    animation: hidePage 0.6s forwards;
  }
}

.page {
  width: 100%;
  min-height: 100%;
  position: relative;
  .page-title {
    font-size: 24px;
    letter-spacing: 0.2em;
    text-align: center;
    position: absolute;
    z-index: 10;
    top: 65px;
    left: 50%;
    transform: translateX(-50%);
  }
  @media screen and (max-width: 980px) {
    .page-title {
      top: 44.5px;
      font-size: 18px;
    }
  }
}

@keyframes hidePage {
  from {
    opacity: 1;
  } to {
    opacity: 0;
  }
}

@keyframes togglePage {
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
}

.primary-btn {
  background-color: $main-blue;
  border-radius: 20px;
  padding: 5px 30px;
  font-size: 16px;
  width: fit-content;
}

.selectDisable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}