.home {
    div {
        .logo {
            width: 650px;
            max-width: 80vw;
            margin-bottom: 35px;
        }
        p {
            font-weight: 100;
            font-size: 22px;
        }
    }
    .primary-btn {
        position: fixed;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
    }
    @media screen and (max-width: 980px) {
        div {
            .logo {
                margin-bottom: 20px;
            }
            p {
                font-size: 16px;
                width: 70vw;
                text-align: center;
            }
        }
        .primary-btn {
            bottom: 18vh;
        }
    }
}