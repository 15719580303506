.about-us {
    img {
        margin-right: 100px;
        width: 350px;
        height: fit-content;
    }
    h2 {
        letter-spacing: 0.2em;
        font-style: normal;
        font-weight: bold;
        font-size: 55px;
        margin-bottom: 30px;
    }
    .text {
        max-width: 520px;
    }
    @media screen and (max-width: 1400px) {
        img {
            width: 300px;
        }
        h2 {
            font-size: 50px;
        }
    }
    @media screen and (max-width: 1100px) {
        align-items: flex-start !important;
        padding-top: 100px;
    }
    @media screen and (max-width: 980px) {
        .container {
            flex-direction: column !important;
            align-items: center !important;
            justify-content: inherit !important;
        }
        h2 {
            font-size: 30px;
            margin-bottom: 10px;
        }
        img {
            width: 140px;
            margin: 0px 0 20px;
        }
    }
}