.transition {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    top: 0;
    background: radial-gradient(50% 50% at 50% 50%, rgba(80, 109, 133, 0) 0%, rgba(80, 109, 133, 0.2) 100%), linear-gradient(
        180deg
        , rgba(80, 109, 133, 0) 0%, rgba(80, 109, 133, 0.2) 100%), #19181D;
    .circle {
        position: fixed;
        border-radius: 50%;
        width: 200vh;
        height: 200vh;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    
    .circle-1 {
    background: rgba(0, 0, 0, 0.4);
    transform: translateX(-50%) translateY(-50%) scale(0.4);
    z-index: 100;
    }
    
    .circle-2 {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
    transform: translateX(-50%) translateY(-50%) scale(0.4);
    z-index: 90;
    }
    
    .circle-3 {
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 59.38%, rgba(0, 0, 0, 0.4) 100%);
    transform: translateX(-50%) translateY(-50%) scale(0.6);
    z-index: 80;
    }
    
    .circle-4 {
    background: radial-gradient(50% 50% at 50% 50%, rgba(25, 24, 29, 0) 71.35%, rgba(25, 24, 29, 0.4) 100%);
    transform: translateX(-50%) translateY(-50%) scale(0.8);
    z-index: 70;
    }
    
    .circle-5 {
    background: radial-gradient(50% 50% at 50% 50%, rgba(25, 24, 29, 0) 71.35%, rgba(25, 24, 29, 0.4) 100%);
    z-index: 60;
    }
}