.floating-icons {
    position: fixed !important;
    z-index: 200;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    div {
        position: absolute;
    }
    .left-icons {
        span {
            margin: 0px 8px;
            transition: 0.2s;
            &:hover {
                color: rgb(163, 163, 163);
            }
        }
    }
    .right-icons {
        right: 0;
    }
    @media screen and (max-width: 1100px) {
        bottom: 30px;
    }
}