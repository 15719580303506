.header {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 100;
    .logo {
        cursor: pointer;
        width: 250px;
        position: absolute;
        left: 0;
    }
    .header-right-side-content {
        position: absolute;
        right: 0;
    }
    .menu-icon {
        width: 40px;
        height: 18px;
        cursor: pointer;
        position: relative;
        &.opened {
            span {
                &:first-of-type {
                    transform: rotate(-45deg);
                    top: 10px;
                    right: 5px;
                }
                &:last-of-type {
                    transform: rotate(45deg);
                    top: 10px;
                    left: -5px;
                }
            }
        }
        span {
            width: 40px;
            height: 3.5px;
            background: white;
            position: absolute;
            transition: 0.3s;
            transition-timing-function: cubic-bezier(0.05, 0.35, 0.07, 1.31);
            &:first-of-type {
                right: 10px;
            }
            &:last-of-type {
                top: 16px;
            }
        }
    }
    @media screen and (max-width: 1100px) {
        top: 30px;
        .logo {
            width: 50px;
        }
        .header-right-side-content {
            top: 15.25px;
        }
    }
}