.contact {
    .form-container {
        width: 500px;
        max-width: 100%;
        padding: 15px;
        form {
            margin-bottom: 10px;
        }
        div {
            width: 100%;
            margin-bottom: 15px;
        }
        input, textarea {
            width: 100%;
            padding: 8px 30px;
            border-radius: 20px;
            border: 2px solid $main-blue;
            color: white;
            background: none;
            &::placeholder {
                color: rgba($color: #ffffff, $alpha: 0.8);
            }
            &:focus {
                outline: none;
            }
        }
        textarea {
            height: 250px;
        }
    }
}