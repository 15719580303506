.project-page {
    position: fixed !important;
    top: 100%;
    left: 0;
    animation: showUp 0.5s forwards;
    z-index: 25;
    * {
        color: white;
    }
    .text-center {
        position: fixed;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
    }
    .project-content {
        position: relative;
    }
    .project-side-bar {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        &:first-of-type {
            left: 0;
        }
        &:last-of-type {
            right: 0;
        }
        .sections-list {
            width: 220px;
            li {
                padding: 18px 5px 5px;
                border-bottom: 1.2px solid $main-blue;
                font-weight: bold;
                font-size: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                &.selected {
                    &::after {
                        content: '';
                        width: 8px;
                        height: 8px;
                        background: white;
                        border-radius: 50%;
                        position: relative;
                        margin-left: 10px;
                    }
                }
            }
        }
        .social-media-list {
            li {
                margin-bottom: 30px;
                &:last-of-type {
                    margin-bottom: 0px;
                }
            }
        }
    }
    .project-section {
        width: 50%;
        height: 70vh;
        position: relative;
        z-index: 1;
        div {
            position: absolute;
            width: 100%;
            top: 80%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            transition: 1s;
            z-index: 1;
            opacity: 0;
            &.selected {
                animation: SectionShowUp 0.6s forwards;
            }
            img {
                width: 100%;
            }
            &.section2 {
                img {
                    display: block;
                    width: 80%;
                    margin: auto;
                }
            }
        }
    }
    .back-to-projects-btn {
        position: fixed;
        bottom: 50px;
        z-index: 100;
        left: 50%;
        transform: translateX(-50%);
    }
    @media screen and (max-width: 1100px) {
        .project-section {
            width: 90%;
        }
        .project-side-bar {
            position: absolute;
            top: inherit;
            transform: inherit;
            &:first-of-type {
                left: 0;
                width: 90%;
                right: 0;
                top: 90px;
                z-index: 100;
                margin: 0px 5%;
                position: fixed;
                span {
                    font-size: 20px;
                    font-weight: bold;
                    padding: 18px 5px 5px;
                    border-bottom: 2px solid $main-blue;
                    width: 100%;
                    display: block;
                    position: relative;
                    text-align: center;
                    &::after {
                        content: '';
                        width: 12px;
                        height: 12px;
                        position: absolute;
                        right: 0px;
                        top: 50%;
                        transform: translateY(-50%);
                        background-image: url(../../public/assets/icons/arrow-down.svg);
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                }
                ul {
                    width: 100%;
                    position: absolute;
                    top: 0px;
                    left: 0;
                    position: relative;
                    z-index: 100;
                    background: radial-gradient(50% 50% at 50% 50%, rgba(80, 109, 133, 0) 0%, rgba(80, 109, 133, 0.2) 100%), linear-gradient(
                          180deg
                          , rgba(80, 109, 133, 0) 0%, rgba(80, 109, 133, 0.2) 100%), #19181D;
                    &::after {
                      content: '';
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      background: rgba(0, 0, 0, 0.4);
                      z-index: -1;
                    }
                }
            }
            &:last-of-type {
                left: 50%;
                transform: translateX(-50%);
                bottom: 30px;
                position: fixed;
                width: fit-content;
                .social-media-list {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    li {
                        margin-bottom: 0px;
                        margin-right: 30px;
                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 980px) {
        .project-section {
            height: 75vh;
        }
        .back-to-projects-btn {
            bottom: 18vh;
        }
    }
}

@keyframes showUp {
    from {
        top: 100%;
    } to {
        top: 0;
    }
}

@keyframes SectionShowUp {
    0% {
        z-index: 1;
        top: 80%;
        opacity: 0;
    }
    100% {
        z-index: 100;
        top: 50%;
        opacity: 1;
    }
}