.projects {
    .projects-content-container {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .projects-content {
            width: 100%;
            position: relative;
        }
        .projects-list {
            .project {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                &.expanded {
                    .big {
                        opacity: 1;
                        transform: scale(3.9) translate(-12%, -12%);
                    }
                    .small {
                        transform: scale(3.9) translate(-12%, -12%);
                        opacity: 0;
                    }
                }
                img {
                    width: 100%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    transition: 1.2s;
                }
                .big {
                    opacity: 0;
                }
                .small {
                    opacity: 1;
                }
            }
        }
    }
    .projects-dots {
        width: 200px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 100px;
        .dot {
            width: 12px;
            height: 12px;
            background-color: white;
            border-radius: 50%;
            margin: 0px 8px 5px 5px;
            transition: 0.5s;
            opacity: 0.2;
            flex-shrink: 0;
            &.selected {
                opacity: 1;
            }
        }
    }
    .select-project-info {
        position: fixed;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
    }
    @media screen and (max-width: 980px) {
        .select-project-info {
            bottom: 10vh;
        }
        .expanded {
            .big {
                transform: scale(3.3) translate(-12%, -12%) !important;
            }
            .small {
                transform: scale(3.3) translate(-12%, -12%) !important;
            }
        }
    }
}

@keyframes growLogo {
    0% {

    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: scale(3.9) translate(-12%, -12%);
    }
}