.menu {
    position: fixed !important;
    width: 100%;
    height: 100%;
    transition: 0.6s;
    top: 100%;
    opacity: 0;
    z-index: 80;
    &.toggle-up {
        top: 0;
        opacity: 1;
    }
    .links {
        li {
            list-style-type: none;
            margin: 0 40px;
            cursor: pointer;
            &:hover {
                a {
                    color: rgb(163, 163, 163);
                }
            }
            a {
                font-size: 30px;
                color: white;
                transition: 0.2s;
            }
        }
    }
    .language-picker {
        position: fixed;
        top: 44.5px;
        z-index: 100;
    }
    @media screen and (max-width: 1100px) {
        top: 0;
        left: 100vw;
        &.toggle-up {
            left: 0;
            opacity: 1;
        }
        .links {
            flex-direction: column !important;
            li {
                margin: 0px 0px 50px;
                &:last-of-type {
                    margin: 0px;
                }
            }
        }
    }
}